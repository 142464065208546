import CryptoJS from 'crypto-js'

export default class AesCrypto {
  static GetEncryptString (plainText) {
    let key = this.GetAesKey()
    let iv = this.GetAesIv()

    key = CryptoJS.enc.Utf8.parse(key)
    iv = CryptoJS.enc.Utf8.parse(iv)
    // plainText = CryptoJS.enc.Utf8.parse(plainText)

    let encrypted = CryptoJS.AES.encrypt(plainText, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 })
    return encrypted.toString()
  }

  static GetDecryptString (plainText) {
    let key = this.GetAesKey()
    let iv = this.GetAesIv()

    key = CryptoJS.enc.Utf8.parse(key)
    iv = CryptoJS.enc.Utf8.parse(iv)

    let decrypted = CryptoJS.AES.decrypt(plainText, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 })
    // console.log('decode=' + decrypted.toString(CryptoJS.enc.Utf8))
    return decrypted.toString(CryptoJS.enc.Utf8)
  }

  static GetAesKey () {
    return sessionStorage.getItem('aesKey')
  }
  static GetAesIv () {
    return sessionStorage.getItem('aesIv')
  }

  static base64Encode (data) {
    let wordArray = CryptoJS.enc.Utf8.parse(data)
    return CryptoJS.enc.Base64.stringify(wordArray)
  }

  static base64Decode (data) {
    let wordArray = CryptoJS.enc.Base64.parse(data)
    return wordArray.toString(CryptoJS.enc.Utf8)
  }
}
