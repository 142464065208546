import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import MyPage from '../views/MyPage.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '로그인',
    component: Login
  },
  {
    path: '/logout',
    name: '로그아웃',
    component: Login
  },
  {
    path: '/myPage',
    name: '마이페이지',
    component: MyPage
  },
  {
    path: '/main',
    name: '메인뷰(사이드바)',
    component: () => import('../views/MainLayout.vue'),
    children: [
      {
        path: 'storage',
        name: '재고현황',
        component: () => import('../views/menu/Storage.vue')
      },
      {
        path: 'deliveryList',
        name: '거래명세표',
        component: () => import('../views/menu/DeliveryTab.vue')
      },
      {
        path: 'planVsResult',
        name: '납품계획/실적',
        component: () => import('../views/menu/PlanVsResult.vue')
      },
      {
        path: 'inputList',
        name: '납품내역',
        component: () => import('../views/menu/InputList.vue')
      },
      {
        path: 'cost',
        name: '원가내역',
        component: () => import('../views/menu/Cost.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
