
import HttpService from './HttpService'
// import HttpAuthService from './HttpAuthService'
import ConstDef from '../../../../MesSmartVue/src/share/ConstDef'
import ParamModel from '../domain/ParamModel'
import VendorInfoModel from '../domain/VendorInfoModel'
import JsEncrypt from 'jsencrypt/bin/jsencrypt'

export default class LoginByB2bUserIdAndPasswdProc {
  static SendParamModelNoExponent (userId, passwd, aesKey, aesIv, rsaKey) {
    const encrypt = new JsEncrypt()
    encrypt.setPublicKey(rsaKey.publicKey)

    const jsonObject = {
      proc: 'spLoginByB2bUserIdAndPasswd',
      ps: {
        aesKey: aesKey,
        아이디: encrypt.encrypt(userId),
        암호: encrypt.encrypt(passwd),
        aesIv: aesIv
      }
    }
    const plainText = JSON.stringify(jsonObject) // '{"version":"20180509.1","userId":"U00488","password":"eluonlbs","session":"nx49ea2hmv","forceCont":true,"receiver":true}'
    const param = new ParamModel()
    param.command = ConstDef.CONNECT
    param.body = plainText
    return HttpService.loginNoExponent(param)
  }

  static MakeModel (data) {
    const json = JSON.parse(data.body)
    const vendorInfo = new VendorInfoModel()
    vendorInfo.authResult = json.authResult
    vendorInfo.암호 = json.passwd
    vendorInfo.거래처코드 = json.division
    vendorInfo.거래처명 = json.userName
    vendorInfo.아이디 = json.userId
    vendorInfo.permission = json.permission
    // b2bUserInfo.token = json.token
    return vendorInfo
  }
}
