<template>
  <div class="my-page-wrap">
    <div class='content'>
      <div class='title'>마이페이지</div>
      <div class='my-info-wrap mt-100'>
        <DxForm
          ref='mypageRef'
          id="form"
          :col-count="1"
          :form-data.sync="vendorInfoSource"
        >
          <DxGroupItem :col-count="2">
            <DxItem
              :editor-options="{disabled: true}"
              data-field="아이디">
                <DxLabel text="아이디"/>
                <DxRequiredRule message="항목을 입력하세요"/>
            </DxItem>
            <DxItem
              :editor-options="{disabled: true}"
              data-field="거래처명">
                <DxLabel text="거래처명"/>
                <DxRequiredRule message="항목을 입력하세요"/>
            </DxItem>
            <DxItem
              data-field="암호"
              :editor-options="passwdOption"
            >
              <DxLabel text="비밀번호"/>
              <DxStringLengthRule
                :max="16"
                :min="8"
                message="비밀번호는 8-16자리 입니다."
              />
            </DxItem>
            <DxItem
              data-field="암호확인"
              :editor-options="passwdOption"
            >
              <DxLabel text="비밀번호확인"/>
              <DxCompareRule
                :comparison-target="passwordComparison"
                message="비밀번호 와 확인 비밀번호가 맞지 않습니다."
              />
            </DxItem>
          </DxGroupItem>
          <DxGroupItem
            :col-span="1"
          >
          </DxGroupItem>
          <DxGroupItem
            :col-count="2"
          >
            <DxButtonItem
              :button-options="svaeButtonOptions"
              horizontal-alignment="right"
            />
            <DxButtonItem
              :button-options="closeButtonOptions"
              horizontal-alignment="left"
            />
          </DxGroupItem>
        </DxForm>
      </div>
      <DxPopupGen
        :visible.sync="popupCropVisible"
        :drag-enabled="true"
        :close-on-outside-click="false"
        :show-title="true"
        :width="460"
        :height="820"
        title="사진 등록"
      >
        <!-- <ImageCrop v-bind:popup='true' v-on:savePhoto="onSaveDriverPhoto"/> -->
      </DxPopupGen>

    </div>
    <loading :active.sync="isLoading" color='rgb(2, 110, 156)' loader='bars'></loading>
  </div>
</template>

<script>
import ConstDef from '../../../MesSmartVue/src/share/ConstDef'
import AppLib from '../../../MesSmartVue/src/share/AppLib'
import Loading from 'vue-loading-overlay'
import DxForm, {
  DxGroupItem,
  DxButtonItem,
  DxLabel,
  DxItem
} from 'devextreme-vue/form'
import {
  DxRequiredRule,
  DxCompareRule,
  DxStringLengthRule
} from 'devextreme-vue/validator'
import { DxPopup as DxPopupGen } from 'devextreme-vue/popup'
// import ImageCrop from './ImageCrop'

export default {
  name: 'myPage',
  components: {
    Loading,
    // DxButton,
    DxForm,
    DxGroupItem,
    DxButtonItem,
    DxLabel,
    DxItem,
    DxRequiredRule,
    DxCompareRule,
    DxStringLengthRule,
    DxPopupGen
    // ImageCrop
  },
  data: function () {
    return {
      isLoading: false,
      userImage: '/image/user-circle.png',
      userImageBlob: null,
      vendorInfoSource: null,
      vendor: null,
      isCouncelor: false,
      closeOption: null,
      popupCropVisible: false,
      svaeButtonOptions: {
        width: 200,
        icon: 'save',
        text: '저장',
        type: 'success',
        focusStateEnabled: true,
        onClick: (e) => {
          if (this.isLoading) {
            return
          }
          if (this.$refs.mypageRef.instance.validate().isValid) {
            this.saveUserInfo()
          }
        }
      },
      closeButtonOptions: {
        width: 200,
        icon: 'close',
        text: '닫기',
        type: 'danger',
        focusStateEnabled: true,
        onInitialized: (args) => {
          this.closeOption = args.component
        },
        onClick: (e) => {
          // this.$router.push('/main')
          this.goToUrl()
        }
      },
      passwdOption: {
        mode: 'password'
      },
      beforeUrl: null,
      orgPasswd: null
    }
  },
  created () {
    document.title = ConstDef.TAB_TITLE
    this.beforeUrl = this.$route.query.beforeUrl
    if (this.$route.query.initpa !== undefined) {
      this.$snotify.error('임시비밀번호 입니다. 비밀번호를 변경하여 주세요.')
    }
    this.vendor = this.$store.getters.getVendorInfo
    console.log(this.vendor)
    this.vendorInfoSource = this.$store.getters.getVendorInfo
    if (this.$route.query.initpa !== undefined) {
      this.vendorInfoSource.암호 = null
      this.vendorInfoSource.암호확인 = null
    } else {
      this.vendorInfoSource.암호확인 = this.vendorInfoSource.암호
    }

    if (this.vendor.userImage != null) {
      this.userImage = ConstDef.apiUrl + '/imgLoadAll?file=' + this.vendor.userImage
      // this.userImage = this.vendor.imgPath
    }
    /*
    if (this.$store.getters.getTempData !== null) {
      this.vendorInfoSource = this.$store.getters.getTempData
      this.orgPasswd = this.$Q.extend(true, {}, this.vendorInfoSource)
      this.$store.commit('deleteTempData')
    }
    */
  },
  mounted () {
    this.$Q('.my-page-wrap').css('height', (screen.height) + 'px')
  },
  updated () {
  },
  beforeDestroy: function () {

  },
  methods: {
    goToUrl (url) {
      if (this.beforeUrl !== undefined || this.beforeUrl !== null) {
        location.href = this.beforeUrl
      } else {
        location.href = '/main'
      }
    },
    getVendorInfo () {

    },
    passwordComparison () {
      return this.vendorInfoSource.암호
    },
    onLoadImg () {

    },
    onFileSelect (e) {
      /*
      this.$store.commit('saveTempData', this.vendorInfoSource)
      let url = '/crop?src=' + this.userImage
      // let url ='crop?src=/common-img/vendor-circle.png'
      this.$router.push(url) */
      this.popupCropVisible = true
    },
    onSaveDriverPhoto () {
      if (this.$store.getters.getTempArgData !== null) {
        this.userImageBlob = this.$store.getters.getTempArgData
        this.userImage = URL.createObjectURL(this.userImageBlob)
      }
      this.popupCropVisible = false
    },
    async saveUserInfo () {
      console.log(this.vendorInfoSource)
      if (this.vendorInfoSource.암호.toLowerCase() === this.vendorInfoSource.아이디.toLowerCase() || this.vendorInfoSource.암호.toLowerCase() === '123456789') {
        this.$snotify.error('유효하지않은 비밀번호 입니다.')
        return
      }
      if (!AppLib.validatePasswd(this.vendorInfoSource.암호)) {
        this.$snotify.error('비밀번호는 문자와 숫자를 혼합하여 설정하여 주세요.')
        return
      }
      // this.isLoading = true
      // let savedImgPath = this.vendor.userImage
      // if (this.userImageBlob !== null) {
      //   const form = new FormData()
      //   form.append('file', this.userImageBlob)
      //   const uploadUrl = '/myPhotoUpload'
      //   await FileService.upload(form, uploadUrl)
      //     .then((data) => {
      //       console.log(data)
      //       if (data.RESULT === 'SUCCESS') {
      //         if (this.userImageBlob !== null) {
      //           savedImgPath = data.PATH
      //           this.userImageBlob = null
      //         }
      //       }
      //     })
      //     .catch(error => {
      //       this.isLoading = false
      //       if (error.response !== undefined && error.response.status === ConstDef.GENERRAL_ERROR) {
      //         this.$snotify.error(error.response.data.message)
      //       } else {
      //         console.log(error)
      //       }
      //     })
      // }
      // save vendor INFO
      const temp = {
        거래처코드: this.vendorInfoSource.거래처코드,
        암호: this.vendorInfoSource.암호,
        updatetime: AppLib.getNow(),
        updateid: this.vendorInfoSource.아이디
        // 포토url: savedImgPath
      }
      // if (savedImgPath === null) {
      //   delete temp.userImage
      // }
      const pk = ['거래처코드']
      const array = []
      array.push(temp)
      await this.$_sp.runUpdateSqlProc('거래처정보', pk, null, array)
        .then((data) => {
          this.isLoading = false
          this.$snotify.info('저장 되었습니다.')
          this.vendor.암호 = this.vendorInfoSource.암호
          this.$store.commit('saveUserInfo', this.vendor)
          // this.$router.push('/main')
          this.goToUrl()
        })
        .catch(error => {
          this.isLoading = false
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    },
    validatePasswd (e) {
      console.log(e.value)
      if (this.$route.query.암호 !== undefined) {
        if (this.orgPasswd.암호 === e.value) {
          e.rule.message = '비밀번호를 재설정 하여주세요.'
          return false
        }
      }
      const pw = e.value
      // let num = pw.search(/[0-9]/g)
      // let eng = pw.search(/[a-z]/ig)
      if (pw.search(/\s/) !== -1) {
        e.rule.message = '비밀번호는 공백 없이 입력해주세요.'
        return false
      }
      /* else if (num < 0 || eng < 0) {
        e.rule.message = '영문, 숫자 각 한자 이상씩 혼합하여 입력해주세요.'
        return false
      }
      */
      return true
    }

  }
}

</script>

<style lang="scss">
.my-page-wrap {
  background-color: rgb(245, 244, 244);
  width:100%;
  padding-top: 50px;
  .content {
    padding:40px;
    width:800px;
    height:250px;
    margin: 0 auto;
    border:1px solid rgb(188, 188, 188);
    background-color: white;
    .title{
      border-bottom:1px solid rgb(188, 188, 188);
      font-size:1.3rem;
      font-weight: bold;
      padding-bottom: 4px;
    }
    #my-photo-reg {
      text-align:center;
      padding: 16px;
      border:0px solid #ddd;

      #my-photo{
        margin:0 auto;
        overflow:hidden;
        width:160px;
        height:160px;
        border-radius: 50%;
        img {
          width:100%;
        }
      }
    }
    .photo-select{
      margin-top:10px;
    }
}
  .my-info-wrap{
    margin-top: 25px;
  }
}
</style>
