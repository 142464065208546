import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    vendorInfo: null,
    aesKey: null,
    aesIv: null,
    userInfo: null,
    topMenu: null,
    topMenuTab: null,
    baseData: null,
    sysParam: null,
    tempArg: null,
    temp: null,
    carType: null,
    customerInfo: null,
    rawMeterial: null,
    companyInfo: null
  },
  getters: {
    getAesKey: function (state) {
      if (state.aesKey === null) {
        state.aesKey = sessionStorage.getItem('aesKey')
      }
      return state.aesKey
    },
    getAesIv: function (state) {
      if (state.aesIv === null) {
        state.aesIvSingle = sessionStorage.getItem('aesIv')
      }
      return state.aesIv
    },
    getBaseDataByType: (state) => (id) => {
      const baseDataArray = []
      if (state.baseData === null) {
        state.baseData = JSON.parse(sessionStorage.getItem('baseData'))
      }
      if (state.baseData !== null) {
        for (let i = 0; i < state.baseData.length; i++) {
          if (state.baseData[i].상위코드 === id) {
            baseDataArray.push(state.baseData[i])
          }
        }
      }
      return baseDataArray
    },
    getGroupName (state) {
      if (state.sysParam === null) {
        state.sysParam = JSON.parse(sessionStorage.getItem('sysParam'))
      }
      const result = state.sysParam.find(item => item.key === '생산그룹명')
      if (result === undefined || result === null) return null
      return result.value
    },
    getSaveId (state) {
      const result = localStorage.getItem('isSaveId')
      // // console.log('called getSaveId result=' + result)
      if ((result !== null && result !== undefined) || result === 'Y') {
        return localStorage.getItem('saveId')
      } else {
        return null
      }
    },
    getSysParam (state) {
      if (state.sysParam === null) {
        state.sysParam = JSON.parse(sessionStorage.getItem('sysParam'))
      }
      return state.sysParam
    },
    getVendorInfo: function (state) {
      if (state.vendorInfo === null) {
        state.vendorInfo = JSON.parse(sessionStorage.getItem('vendorInfo'))
      }
      return state.vendorInfo
    },
    getTopMenuTabByUrl: (state) => (url) => {
      // console.log(url)
      if (state.topMenuTab === null) {
        state.topMenuTab = JSON.parse(sessionStorage.getItem('topMenuTab'))
      }
      // console.log(state.topMenuTab)
      if (state.topMenuTab !== null) {
        for (let i = 0; i < state.topMenuTab.length; i++) {
          if (state.topMenuTab[i].url === url) {
            return state.topMenuTab[i]
          }
        }
      }
      return null
    }
  },
  mutations: {
    saveAesKey (state, key) {
      state.aesKey = key
      sessionStorage.setItem('aesKey', key)
    },
    saveAesIv (state, iv) {
      state.aesIv = iv
      sessionStorage.setItem('aesIv', iv)
    },
    saveBaseData (state, baseData) {
      state.baseData = baseData
      sessionStorage.setItem('baseData', JSON.stringify(baseData))
    },
    saveId (state, id) {
      localStorage.setItem('isSaveId', 'Y')
      localStorage.setItem('saveId', id)
    },
    saveTopmenu (state, topMenu) {
      state.topMenu = topMenu
      sessionStorage.setItem('topMenu', JSON.stringify(topMenu))
    },
    saveTopMenuTab (state, topMenuTab) {
      // console.log('===========================')
      // console.log(topMenuTab)
      state.topMenuTab = topMenuTab
      sessionStorage.setItem('topMenuTab', JSON.stringify(topMenuTab))
    },
    saveSysParam (state, param) {
      state.sysParam = param
      sessionStorage.setItem('sysParam', JSON.stringify(param))
    },
    saveVendorInfo (state, vendorInfo) {
      state.vendorInfo = vendorInfo
      sessionStorage.setItem('vendorInfo', JSON.stringify(vendorInfo))
    },
    saveCustomerInfo (state, param) {
      state.customerInfo = param
      sessionStorage.setItem('customerInfo', JSON.stringify(param))
    },
    saveCompany (state, param) {
      state.companyInfo = param
      sessionStorage.setItem('companyInfo', JSON.stringify(param))
    },
    saveCarType (state, param) {
      state.carType = param
      sessionStorage.setItem('carType', JSON.stringify(param))
    },
    saveRawMeterial (state, param) {
      state.rawMeterial = param
      sessionStorage.setItem('rawMeterial', JSON.stringify(param))
    },
    deleteAesKeyIv (state) {
      state.aesKey = null
      state.aesIv = null
      sessionStorage.removeItem('aesKey')
      sessionStorage.removeItem('aesIv')
    },
    deleteBaseData (state) {
      state.baseData = null
      sessionStorage.removeItem('baseData')
    },
    deleteSaveId (state) {
      localStorage.setItem('isSaveId', 'N')
      localStorage.removeItem('saveId')
    },
    deleteVendorInfo (state) {
      state.vendorInfo = null
      sessionStorage.removeItem('vendorInfo')
    }
  },
  actions: {
  },
  modules: {
  }
})
