<template>
  <div id="app">
    <router-view/>
    <vue-snotify></vue-snotify>
  </div>
</template>
<script>

import axios from 'axios'
// import store from './store'
import router from './router'

import ConstDef from '../../MesSmartVue/src/share/ConstDef'
import koMessages from './language/ko.json'
import { locale, loadMessages } from 'devextreme/localization'

export default {
  name: 'main-app',
  data: function () {
    return {
      isLogined: false
    }
  },
  created () {
    if (this.$route.path !== '/main' && this.$route.path !== '/') {
      const url = decodeURI(this.$route.path)
      this.$router.replace(url)
    }
    loadMessages(koMessages)
    locale(navigator.language)

    const arr = window.location.host.split(':')
    const host = window.location.protocol + '//' + arr[0]

    if (process.env.NODE_ENV === 'production') {
      const port = window.location.port
      ConstDef.apiUrl = `${host}:${port}${ConstDef.apiUrlProduction}`
      ConstDef.manualUrl = host + ConstDef.manualUrlProduction
      ConstDef.stompUrl = `${host}:${port}${ConstDef.stompPortProduction}`
      ConstDef.authUrl = ConstDef.authUrlProduction
    } else {
      ConstDef.apiUrl = host + ConstDef.apiUrlEvaluation
      ConstDef.manualUrl = host + ConstDef.manualUrlEvaluation
      ConstDef.stompUrl = `${host}${ConstDef.stompPortEvaluation}`
      ConstDef.authUrl = ConstDef.authUrlEvaluation
    }
    console.log(ConstDef.apiUrl)
  },
  mounted () {

  },
  beforeDestroy: function () {

  },
  methods: {

  }
}

axios.interceptors.response.use(response => response, (error) => {
  console.log('interceptors Error')
  console.log(error.response)
  if (error.response === undefined) {
    alert('시스템 이상 잠시후 다시 접속 하세요')
    return Promise.reject(error)
  } else if (error.response.status === ConstDef.FORBIDDEN) {
    alert('권한 없음')
  } else if (error.response.status === ConstDef.UNAUTHORIZED) {
    router.replace('/')
  } else if (error.response.status === ConstDef.BAD_REQUEST || error.response.status === ConstDef.NOT_FOUND) {
    console.log('others System Error')
    alert('시스템 이상(Erro Code:' + error.response.status + ')')
    return Promise.reject(error)
  } else if (error.response.data.status === ConstDef.DUPLICATE_LOGIN) {
    console.log(error.response)
    alert(error.response.data.message)
    router.replace('/')
  } else {
    console.log(error)
    return Promise.reject(error)
  }
})
/*
{
    "version": "0.2.0",
    "configurations": [
      {
        "type": "chrome",
        "request": "launch",
        "name": "vuejs: chrome",
        "url": "http://localhost:4200",
        "webRoot": "${workspaceFolder}/src",
        "breakOnLoad": true,
        "sourceMapPathOverrides": {
          "webpack:///./src/*": "${webRoot}/*"
        }
      }
    ]
  }
  */
</script>

<style lang="scss">
#app {
  height:100%;
  .snotify{
    width:330px;
  }
}
.dx-button-text{
  font-size:0.9rem;
}

</style>
