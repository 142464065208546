<template>
  <div class="login-page">
    <div class='container'>
      <div class='login-content'>
        <table class='login-table'>
          <colgroup>
              <col width="40px">
              <col width="180px">
              <col width="74px">
          </colgroup>
          <tbody>
            <tr id='logo-image'>
              <td colspan="3"></td>
            </tr>
            <tr id='place'>
              <td colspan="3">오토다임&협력업체</td>
            </tr>
            <tr>
              <td>아이디 :</td>
              <td><dx-text-box v-model='아이디'
                  :show-clear-button="true"
                  maxLength="8"/></td>
              <td rowspan="2">
                <dx-button class='login-button'
                  :width="70"
                  :height="64"
                  type='default'
                  text="로그인"
                  styling-mode="contained"
                  :focusStateEnabled = 'false'
                  @click="onLogin($event)"
                />
              </td>
            </tr>
            <tr>
              <td>비번 :</td>
              <td><!--<input type='text' v-model='암호'/>-->
                <dx-text-box v-model='암호'
                  :show-clear-button="true"
                  mode="password" maxLength="12"
                  @enter-key="onEnterKey"/>
              </td>
              <td></td>
            </tr>
            <tr>
              <td id='id-save' colspan="3">
                <dx-check-box
                  v-model="isIdSave"
                  text="아이디 기억하기"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div id='copy-right' @click='onClose()'>
        <span>Copyright 2022 Megazone All rights reserved</span>
      </div>
      <loading :active.sync="isLoading"></loading>
    </div>

  </div>
</template>
<script src="<%= BASE_URL %>rsa_js/jsbn.js"></script>
<script>
import HttpService from '../share/service/HttpService'
import ConstDef from '../../../MesSmartVue/src/share/ConstDef'
import AppLib from '../../../MesSmartVue/src/share/AppLib'
import Loading from 'vue-loading-overlay'
import LoginByB2bUserIdAndPasswdProc from '../share/service/LoginByB2bUserIdAndPasswdProc'
import { DxTextBox, DxButton } from 'devextreme-vue'
import { DxCheckBox } from 'devextreme-vue/check-box'
export default {
  name: 'login',
  data: function () {
    return {
      아이디: '우진',
      암호: 'ehdyto8065',
      isIdSave: false,
      isLoading: false
    }
  },
  components: {
    Loading,
    DxTextBox,
    DxButton,
    DxCheckBox
  },
  created () {
    document.title = ConstDef.TAB_TITLE
    console.log(ConstDef.apiUrl)
    console.log(ConstDef.apiUrl.indexOf(':8082'))
    if (ConstDef.apiUrl.indexOf(':8082') < 0) {
      this.아이디 = ''
      this.암호 = ''
    }
    const localSaveId = this.getSaveId()
    if (localSaveId !== null && localSaveId !== undefined) {
      this.isIdSave = true
      this.아이디 = localSaveId
    }
    if (this.$route.path.indexOf('logout') >= 0) {
      this.$store.commit('deleteVendorInfo')
      this.$store.commit('deleteAesKeyIv')
      this.$store.commit('deleteBaseData')
      HttpService.logout()
        .then((data) => {
        })
        .catch(error => {
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    }
    if (this.$store.getters.getAesKey === null || this.$store.getters.getAesIv === null) {
      this.createAesKey()
    }
  },
  mounted () {
    this.$Q('.login-page').css('height', window.innerHeight + 'px')
    window.addEventListener('resize', this.handleResize)
  },
  updated () {
  },
  beforeDestroy: function () {
    // console.log('beforeDestroy')
    window.removeEventListener('resize', this.handleResize)
    // this.onClose()
  },
  methods: {
    getSaveId (state) {
      const result = localStorage.getItem('isSaveId')
      if ((result !== null && result !== undefined) || result === 'Y') {
        return localStorage.getItem('saveId')
      } else {
        return null
      }
    },
    handleResize (event) {
      this.$Q('.login-page').css('height', window.innerHeight + 'px')
    },
    onLogin () {
      if (this.아이디 === null || this.아이디 === '') {
        this.$snotify.error('아이디를 입력하세요.')
        return
      }
      if (this.암호 === null || this.암호 === '') {
        this.$snotify.error('비밀번호를 입력하세요.')
        return
      }
      this.setIsSaveId()
      this.login(this.아이디, this.암호)
    },
    async login (아이디, 암호) {
      let rsaKey
      this.isLoading = true
      await HttpService.connectNoExponent()
        .then((data) => {
          rsaKey = data
        })
        .catch(error => {
          this.isLoading = false
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
      await LoginByB2bUserIdAndPasswdProc.SendParamModelNoExponent(아이디, 암호, this.$store.getters.getAesKey, this.$store.getters.getAesIv, rsaKey)
        .then((data) => {
          this.isLoading = false
          if (data.result === ConstDef.SUCCESS) {
            const vendorInfo = LoginByB2bUserIdAndPasswdProc.MakeModel(data)
            if (vendorInfo.authResult === ConstDef.SUCCESS) {
                this.$store.commit('saveVendorInfo', vendorInfo)
                if (vendorInfo.암호 === '12345678a') {
                  this.$router.replace('/myPage?beforeUrl=/&initpa=1')
                } else {
                  this.$router.replace('/main/deliveryList')
                }
            } else {
              console.log('로그인 정보가 정확하지 않습니다')
              this.$snotify.error('로그인 정보가 정확하지 않습니다.')
            }
          }
        })
        .catch(error => {
          this.isLoading = false
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    },
    savePageAccessHis (userInfo, url) {
      const param = [
        {
          accessDate: AppLib.getNow(),
          menuName: '로그인',
          아이디: userInfo.아이디,
          userName: userInfo.userName,
          menuUrl: url
        }
      ]
      this.$_sp.runInsertSqlProc('PAGE_ACCESS_HIS', null, param)
        .then((data) => {
          if (url === '/main') {
            this.$router.replace(url)
          } else {
            location.href = `/main?url=${url}`
          }
        })
        .catch(_error => {
          if (url === '/main') {
            this.$router.replace(url)
          } else {
            location.href = `/main?url=${url}`
          }
        })
    },
    setIsSaveId () {
      if (this.isIdSave) {
        // console.log('save id = '+ this.아이디)
        this.$store.commit('saveId', this.아이디)
      } else {
        this.$store.commit('deleteSaveId')
      }
    },
    createAesKey () {
      const v = 'abcdefghijklmnopqrstuvwxyz0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ'
      const len = v.length
      let session = ''
      for (let i = 0; i < 32; i++) {
        const ran = Math.floor(Math.random() * len)
        session += v.substr(ran, 1)
      }
      this.$store.commit('saveAesKey', session)
      session = ''
      for (let i = 0; i < 16; i++) {
        const ran = Math.floor(Math.random() * len)
        session += v.substr(ran, 1)
      }
      this.$store.commit('saveAesIv', session)
    },
    onEnterKey (event) {
      this.onLogin(null)
    }
  }
}

</script>

<style lang="scss">
.html{
  background-color: white;
}
.login-page{
  position:relative;
  background-color: white;
  .container
  {
    width:650px;
    height:350px;
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    border:1px solid rgb(226, 226, 226);
    background: url('../assets/image/bg.jpg') no-repeat 100%;
    #copy-right{
        font-weight: bold;
        color:#3d3d3d;
        text-align: center;
    }
    .login-content
    {
      margin: 60px 0 10px 300px;
      border:0px solid black;
      height:230px;
      width:340px;
      .login-table{
        width:100%;
        .login-button{
          margin: 0 5px;
        }
        td{
          text-align: left;
        }
        input{
          width:100%;
        }
        #place{
          td{
            padding-bottom: 15px;
            color:#414141;
            font-size: 1.2rem;
            font-weight: bold;
          }

        }
        #logo-image{
          height:60px;
          width:300px;
          background: url('../assets/image/login_title.png') no-repeat 0%;
        }
        #id-save{
          padding-top:10px;
        }
      }

    }
  }
  .dx-checkbox-icon{
    width:20px;
    height:20px;
  }
  .dx-checkbox-text{
    height:20px;
    width:200px;
    padding-top:1px;
    color: black;
  }
  .dx-texteditor{
    background-color: white!important;
    input {
      color:black;
    }
  }

}
</style>
