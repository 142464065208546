export default class VendorInfoModel {
  constructor () {
    this.거래처코드 = null
    this.거래처명 = null
    this.이메일 = null
    this.아이디 = null
    this.암호 = null
    this.permission = null
  }
}
