/* eslint-disable no-useless-escape */
export default class ConstDef {
  static apiUrl = ''
  static apiUrlProduction = '/MesApi/restApi'
  static apiUrlEvaluation = ':8082/restApi'

  static authUrl = ''
  static authUrlProduction = 'https://authmes.landk.co.kr/MesAuth/restApi'
  static authUrlEvaluation = 'http://localhost:8082/restApi'

  static stompPortProduction = '/MesApi/ws'
  static stompPortEvaluation = ':8082/ws'
  static manualUrl = ''
  static manualUrlProduction = ':8082/manual'
  static manualUrlEvaluation = ':4200/manual'
  static stompUrl = ''
  static DATA_BASE = 'SF_MES'
  static WEB_TAB_TITLE='Smart Mes'
  static TAB_TITLE='Smart Mes'

  static 스크랩_회수율 = 0.99
  static 스크랩_비중 = 7.85

  static errRetry = 0
  // Look Up
  static YN_LOOKUP = [
    {
      코드: 'N'
    },
    {
      코드: 'Y'
    }
  ]
  static 도급_LOOKUP = [
    { 코드: '자작' },
    { 코드: '도급' },
    { 코드: '구매' }
  ]
  // system 변수에서 재 할당됨
  static USER_PERMISSION = [
    { 'groupName': '시스템관리자', 'permission': 1 },
    { 'groupName': '경영관리', 'permission': 2 },
    { 'groupName': '생산관리', 'permission': 4 },
    { 'groupName': '구매/영업관리', 'permission': 8 },
    { 'groupName': '연구/기술관리', 'permission': 16 },
    { 'groupName': '물류관리', 'permission': 32 },
    { 'groupName': '기타부서', 'permission': 64 },
    { 'groupName': '생산현장관리자', 'permission': 128 },
    { 'groupName': '생산현장직', 'permission': 256 }
  ]
  static 생산현장직_권한 = 256

  static CRUD_ALLOW_경영 = 1 | 2
  static CRUD_ALLOW_경영_생산 = 1 | 2 | 4
  static CRUD_ALLOW_생산_구매 = 1 | 4 | 8
  static CRUD_ALLOW_생산_연구 = 1 | 4 | 16
  static CRUD_ALLOW_생산_물류 = 1 | 4 | 32
  static CRUD_ALLOW_구매 = 1 | 8

  static CRUD_ALLOW_시스템관리_구매관리 = 1 | 8

  static SYS_USER_PERMISSION_KEY = '사용자권한'

  static WEEK_PLAN_PRODUCT_KEY = 'WEEK_PLAN_PRODUCT'
  static WEEK_PLAN_SEMI_KEY = 'WEEK_PLAN_SEMI'
  static WEEK_PLAN_WIP_KEY = 'WEEK_PLAN_WIP'

  // packet command
  static CONNECT = 0x0000;
  static LOGOUT = 0x0001;
  static EXEC_PROC = 0x0011;
  static EXEC_PROC_STREAM = 0x0012;
  static EXEC_PROC_POI = 0x0021;
  static CONV_GEO_XY = 0x0022;
  static PROC_ALLOC = 0x0031;
  static PAY_CREDIT_CARD = 0x0101;
  static CANCEL_CREDIT_CARD = 0x0102;
  static CLOSE_FORCE = 0xF001;

  // packet result
  static SUCCESS = 0x01;
  static CONTINUE = 0x02;
  static COMPLETE = 0x03;
  static ERR_MESSAGE = 0x10;
  static ERR_SERVER = 0x11;
  static ERR_JSON = 0x12;
  static ERR_NOUSER = 0x16;
  static ERR_PASSWORD = 0x17;
  static ERR_PACKETVER = 0x18;
  static ERR_SESSIONID = 0x19;
  static ERR_PARAMS = 0x21;
  static NETWOTK_RECONNECT = 0x99;
  static ERR_NETWOTK_RECONNECT= 0x98;

  // WAS Error Reponse
  static UNAUTHORIZED = 401
  static NOT_FOUND = 404
  static FORBIDDEN = 403
  static BAD_REQUEST = 400
  static NOT_ACCEPTABLE = 406
  static GENERRAL_ERROR = 500
  static DUPLICATE_LOGIN = 1009

  // BOM일괄등록
  static BOM_BARCH_FIE_PATH = '/content/bomBatch'
  static BOM_BATCH_FILE_NMAE = 'PRESS_BOM_FORM.xlsx'

  // 사급여부(구매구분)
  static 시구 = '시구'
  static 사급 = '사급'

  // 시중구매처(거래처정보)
  static 시중구매 = '시중구매'
  static 고객사그룹 = 'B30'

  // 품목유형
  static 품목유형 = 'S01'
  static 구매품 = 'S0106'
  static 다공정품 = 'S0104'
  static 반제품 = 'S0102'
  static 완제품 = 'S0100'
  // 라인상태
  static 생산라인정상 = 'B2500'
  // 자재유형
  static 자재유형 = 'S04'
  static 자재유형_원자재 = 'S0402'
  static 부자재 = 'S0404'
  static 사급품 = 'S0406'
  static 외주품 = 'S0408' // 프레스외주품
  static 외주직납품 = 'S0409' // 외주직납품
  static 소모품 = 'S0410'
  // 공정유형
  static 블랭킹 = 'B0200'
  static 샤링 = 'B0201'
  static 프레싱 = 'B0202'
  static 프로그레시브 = 'B0203'
  static 프로젝션 = 'B0204'
  static 용접 = 'B0205'
  static 드로잉 = 'B0206'
  static 도급 = 'B0207'
  // 공통코드
  static 공정유형 = 'B02'
  static 단위 = 'B11'
  static EA = 'B1101'
  static 무게 = 'B1102'
  static 직급 = 'B09'
  static 직책 = 'B10'
  static 장소유형 = 'B08'
  static 설비유형 = 'B15'
  static 생산진행상태 = 'B25'
  static 고장계통 = 'B26'
  static 고장유형 = 'B27'
  static 불량원인 = 'B20'
  static 라인중단코드 = 'B28'
  static 설비중단코드 = 'B2899' // 설비고장
  static 제조사 = 'B29'
  static 정산유형 = 'B31'
  static 거래처그룹 = 'B30'
  static 보관불량 = 'B2009'
  static 소속회사 = 'B32'

  // 금형관리
  static 금형사급 = 'B03'
  // 근무형태
  static 근무형태 = [
    { 코드명: '교대없음', 코드: 1 },
    { 코드명: '일 2교대', 코드: 2 },
    { 코드명: '일 3교대', 코드: 3 }
  ]

  static 자재출고 = 0
  static 자재입고 = 1
  static 제품출하 = 2
  static 제품반품 = 3
  static 불량입고 = 5
  static 사급출고 = 6
  static 용변입고 = 7
  static 용변출고 = 8

  // 거래처구분

  static COMPANY_발주사 = '발주사'
  static COMPANY_협력사 = '협력사'
  static COMPANY_TYPE = [
    { 코드명: '발주사', 코드: this.COMPANY_발주사 },
    { 코드명: '협력사', 코드: this.COMPANY_협력사 }
  ]
  // 자재/제품 수불
  static PURCHASE_INOUT_CODE = [
    { 코드명: '자재출고', 코드: 0 },
    { 코드명: '자재입고', 코드: 1 },
    { 코드명: '판매출하', 코드: 2 },
    { 코드명: '사급출고', 코드: 6 },
    { 코드명: '용변입고', 코드: 7 },
    { 코드명: '용변출고', 코드: 8 }
  ]
  static COIL_INOUT_CODE = [
    { 코드명: '자재입고', 코드: 1 },
    { 코드명: '용변입고', 코드: 7 },
    { 코드명: '용변출고', 코드: 8 }
  ]
  static PRODUCTION_INOUT_CODE = [
    { 코드명: '제품출하', 코드: 2 },
    { 코드명: '제품반품', 코드: 3 }
  ]

  static GOODS_INOUT_CODE = [
    { 코드명: '자재출고', 코드: 0 },
    { 코드명: '자재입고', 코드: 1 },
    { 코드명: '제품출하', 코드: 2 },
    { 코드명: '제품반품', 코드: 3 },
    { 코드명: '사급출고', 코드: 6 },
    { 코드명: '제품생산', 코드: '제품생산' }
  ]
  // 불량수불
  static 불량입고 = 5
  static 반송입고 = 3

  static BAD_INOUT_CODE = [
    { 코드명: '불량입고', 코드: this.불량입고 },
    { 코드명: '반송입고', 코드: this.반송입고 }
  ]

  static BAD_PROCESSING_STATE = [
    { 코드명: '미처리', 코드: 0 },
    { 코드명: '불량반품', 코드: 4 },
    { 코드명: '폐기처리', 코드: 6 }
  ]

  static BOM_TYPE = [
    { 코드: 0, 코드명: 'BOM 1차 품목' },
    { 코드: 1, 코드명: 'BOM 전 품목' }
  ]

  static SCRAP_LOOKUP = [
    { 코드명: '폐기' },
    { 코드명: '재활용' },
    { 코드명: '불량폐기' },
    { 코드명: '보관불량' }
  ]
  static BAD_COIL_CAUSE = [
    { 코드명: '불량폐기' },
    { 코드명: '보관불량' }
  ]
  /*
  // user Group
  static SYSTEM_MANAGER = 1
  static TOP_MANAGER = 2
  static YUNHAP_MANAGER = 4
  static HEAD_NANAGER = 8
  static BRANCH_MANAGER = 16
  static GEN_MANAGER = 32
  static BILL_MANAGER = 64
  static COUNCELOR = 128
*/
  // chart palette
  static CHART_PALETTE =[
    'Harmony Light',
    'Soft',
    'Soft Pastel',
    'Pastel',
    'Violet',
    'Bright',
    'Soft Blue',
    'Office',
    'Ocean',
    'Carmine',
    'Dark Moon',
    'Vintage',
    'Material',
    'Green Mist'
  ]

  static QR_PRINT = true
  static ENCODE_BASE64 = false

  // 시스템변수 : ZPL_TYPE1,2 에서 관리됨
  static SEMI_PRODUCT_ZPL = '^XA~TA000~JSN^LT0^MNW^MTT^PON^PMN^LH0,0^JMA^PR6,6~SD15^JUS^LRN^CI0^XZ' +
  '^XA' +
  '^MMT' +
  '^PW799' +
  '^LL1199' +
  '^LS0' +
  '^SEE:UHANGUL.DAT^FS' +
  '^CW1,E:corefont.TTF^CI26^FS' +
  '^FO352,832^GFA,01792,01792,00056,:Z64:' +
  'eJxjYBgFo2AUjAJC4D8S+EOmPtzgAJn6UACGGcxk6sMN7MnUNwoGJwAAPJSYaA==:5BA0' +
  '^FO33,27^GB749,1148,4^FS' +
  '^FO133,27^GB0,1144,3^FS' +
  '^FO245,31^GB0,1144,3^FS' +
  '^FO365,31^GB0,1144,3^FS' +
  '^FO448,31^GB0,820,3^FS' +
  '^FO133,1056^GB235,0,3^FS' +
  '^FT108,1139^A1B,56,74^FH\^FD부품식별표^FS' +
  '^FT208,1165^A1B,45,48^FH\^FD품번^FS' +
  '^FT324,1165^A1B,45,48^FH\^FD품명^FS' +
  '^FO365,681^GB411,0,3^FS' +
  '^FT427,846^A1B,45,48^FH\^FDLOT NO^FS' +
  '^FT680,825^A1B,45,48^FH\^FD수 량^FS' +
  '^FT215,1042^A1B,51,100^FH\^FD{{GOODS_NO}}^FS' +
  '^FT327,1042^A1B,51,{{GOODS_NMAE_FONT}}^FH\^FD{{GOODS_NAME}}^FS' +
  '^FT431,1208^BQN,2,10' +
  '^FH\^FDLA,{{QRCODE}}^FS' +
  '^FO36,724^GB96,0,3^FS' +
  '^FO36,555^GB96,0,3^FS' +
  '^FT106,714^A1B,45,48^FH\^FD공정명^FS' +
  '^FT105,534^A1B,51,69^FH\^FD{{PROCESSING_NAME}}^FS' +
  '^FT426,654^A1B,45,48^FH\^FD{{LOT_NO}}^FS' +
  '^FO562,31^GB0,820,3^FS' +
  '^FT522,836^A1B,45,48^FH\^FD생산일^FS' +
  '^PQ1,0,1,Y^XZ'

  static SEMI_PRODUCT_ZPL2 = '^XA~TA000~JSN^LT0^MNW^MTT^PON^PMN^LH0,0^JMA^PR6,6~SD15^JUS^LRN^CI0^XZ' +
  '^XA' +
  '^MMT' +
  '^PW799' +
  '^LL1199' +
  '^LS0' +
  '^SEE:UHANGUL.DAT^FS' +
  '^CW1,E:corefont.TTF^CI26^FS' +
  '^FO352,832^GFA,01792,01792,00056,:Z64:' +
  'eJxjYBgFo2AUjAJC4D8S+EOmPtzgAJn6UACGGcxk6sMN7MnUNwoGJwAAPJSYaA==:5BA0' +
  '^FO33,27^GB749,1148,4^FS' +
  '^FO133,27^GB0,1144,3^FS' +
  '^FO328,31^GB0,1144,3^FS' +
  '^FO436,31^GB0,1144,3^FS' +
  '^FO519,31^GB0,820,3^FS' +
  '^FO133,1056^GB303,0,3^FS' +
  '^FT108,1139^A1B,56,74^FH\^FD부품식별표^FS' +
  '^FT299,1165^A1B,45,48^FH\^FD품번^FS' +
  '^FT407,1165^A1B,45,48^FH\^FD품명^FS' +
  '^FO439,853^GB337,0,3^FS' +
  '^FO439,681^GB337,0,3^FS' +
  '^FT498,846^A1B,45,48^FH\^FDLOT NO^FS' +
  '^FT718,818^A1B,45,38^FH\^FD수량^FS' +
  '^FT300,1042^A1B,51,144^FH\^FD{{GOODS_NO}}^FS' +
  '^FT410,1042^A1B,51,{{GOODS_NMAE_FONT}}^FH\^FD{{GOODS_NAME}}^FS' +
  '^FT490,1188^BQN,2,9' +
  '^FH\^FDLA,{{QRCODE}}^FS' +
  '^FO36,724^GB96,0,3^FS' +
  '^FO36,555^GB96,0,3^FS' +
  '^FT106,714^A1B,45,48^FH\^FD공정명^FS' +
  '^FT105,534^A1B,51,69^FH\^FD{{PROCESSING_NAME}}^FS' +
  '^FT499,654^A1B,45,48^FH\^FD{{LOT_NO}}^FS' +
  '^FO621,31^GB0,820,3^FS' +
  '^FT593,830^A1B,45,38^FH\^FD생산일^FS' +
  '^FO227,27^GB0,1144,3^FS' +
  '^FT200,1165^A1B,45,48^FH\^FD차종^FS' +
  '^FT199,1044^A1B,51,144^FH\^FD{{CAR_TYPE}}^FS' +
  '^PQ1,0,1,Y^XZ'

  // static 70_50_ZPL =
  // ^XA~TA000~JSN^LT0^MNW^MTT^PON^PMN^LH0,0^LRN^CI0^XZ
  // ^XA
  // ^MMT
  // ^PW559
  // ^LL0400
  // ^LS0
  // ^SEE:UHANGUL.DAT^FS
  // ^CW1,E:corefont.TTF^CI26^FS
  // ^FO32,128^GFA,02048,02048,00064,:Z64:
  // eJxjYBgFo2AUDFVg/58C8GNU/ygYBSMZAADpVDD3:7C1A
  // ^FO32,32^GB496,336,5^FS
  // ^FO196,37^GB0,331,5^FS
  // ^FO201,220^GB322,0,5^FS
  // ^FT42,352^BQN,2,7
  // ^FH\^FDLA,{{QRCODE}}^FS
  // ^FO315,148^GB0,220,5^FS
  // ^FT211,205^A0N,40,60^FH\^FDLOT^FS
  // ^FT322,202^A0N,38,57^FH\^FD{{LOT_NO}}^FS
  // ^FT206,312^A1N,41,50^FH\^FD수량^FS
  // ^FT45,116^A0N,69,64^FH\^FD{{CAR_TYPE}}^FS
  // ^FT201,109^A0N,50,52^FH\^FD{{GOODS_NO}}^FS
  // ^PQ1,0,1,Y^XZ

static ALL_PRODUCT_ZPL =
  '^XA~TA000~JSN^LT0^MNW^MTT^PON^PMN^LH0,0^JMA^PR6,6~SD15^JUS^LRN^CI0^XZ' +
  '^XA' +
  '^MMT' +
  '^PW559' +
  '^LL0400' +
  '^LS0' +
  '^SEE:UHANGUL.DAT^FS' +
  '^CW1,E:corefont.TTF^CI26^FS' +
  '^FO32,128^GFA,02048,02048,00064,:Z64:' +
  'eJxjYBgFo2AUDFVg/58C8GNU/ygYBSMZAADpVDD3:7C1A' +
  '^FO32,32^GB496,336,5^FS' +
  '^FO196,37^GB0,331,5^FS' +
  '^FO201,220^GB322,0,5^FS' +
  '^FT42,352^BQN,2,7' +
  '^FH\^FDLA,{{QRCODE}}^FS' +
  '^FO315,148^GB0,220,5^FS' +
  '^FT211,205^A0N,40,60^FH\^FDLOT^FS' +
  '^FT322,202^A0N,38,57^FH\^FD{{LOT_NO}}^FS' +
  '^FT206,312^A1N,41,50^FH\^FD수량^FS' +
  '^FT45,116^A0N,69,64^FH\^FD{{CAR_TYPE}}^FS' +
  '^FT201,109^A0N,50,52^FH\^FD{{GOODS_NO}}^FS' +
  '^PQ1,0,1,Y^XZ'
}
