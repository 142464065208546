import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router/index'
import store from './store/index'
import './assets/css/app.scss'
import './assets/css/project.scss'
import Snotify, { SnotifyPosition } from 'vue-snotify'
import 'vue-snotify/styles/material.css'
import jquery from 'jquery'
import underscore from 'vue-underscore'

import 'vue-loading-overlay/dist/vue-loading.css'
import 'devextreme/dist/css/dx.common.css'
import 'devextreme/dist/css/dx.light.compact.css'
import StoreProcPlugin from './share/service/plugin/StoreProcPlugin'

Vue.use(StoreProcPlugin)
Vue.config.productionTip = false
Vue.prototype.$Q = jquery
Vue.use(underscore)

const options = {
  toast: {
    position: SnotifyPosition.rightTop,
    timeout: 5000
  }
}
Vue.use(Snotify, options)
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
